<template>
  <div>
    <!-- loading -->
    <v-overlay v-if="$store.state.loading" opacity="100">
      <div class="text-center">
        <div class="headline mb-8">
          Loading
          {{
            userAccount == undefined ||
            eventReport == undefined ||
            eventReportWinner == undefined
              ? "Your Dashboard"
              : ""
          }}
        </div>
        <v-progress-circular
          indeterminate
          size="50"
          color="primary"
        ></v-progress-circular>
      </div>
    </v-overlay>
    <!-- if user -->
    <v-container v-else>
      <!-- toolbar -->
      <v-row>
        <v-col cols="12">
          <v-toolbar dense color="primary" dark flat rounded>
            <v-toolbar-title>Your Dashboard</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <!-- dark mode button -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    depressed
                    dark
                    v-bind="attrs"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    v-on="on"
                    @click="$vuetify.theme.dark = !$vuetify.theme.dark"
                  >
                    <v-icon>{{
                      $vuetify.theme.dark
                        ? "mdi-lightbulb-outline"
                        : "mdi-lightbulb"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  $vuetify.theme.dark
                    ? "Switch to Light Mode"
                    : "Switch to Dark Mode"
                }}</span>
              </v-tooltip>

              <!-- sign out btn -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    depressed
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="onSignOut"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    class="d-none d-sm-inline-flex"
                  >
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-btn>
                </template>
                <span>Sign Out</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
      </v-row>

      <!-- your information -->
      <v-row>
        <v-col col="12" class="d-md-flex">
          <v-card
            outlined
            class="d-md-flex flex-md-column"
            :loading="$store.state.loading"
          >
            <v-card-title
              class="headline font-weight-medium d-none d-sm-block d-md-none text-left"
              >Your Information</v-card-title
            >
            <v-card-title
              class="justify-md-space-between mb-5 d-flex align-center flex-column flex-sm-row"
            >
              <div
                class="headline font-weight-medium d-block d-sm-none d-md-block my-1"
              >
                Your Information
              </div>
              <v-btn
                class="my-1 my-sm-0"
                color="primary"
                text
                small
                @click="termsDialog = true"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                >Your Package Terms</v-btn
              >
              <v-btn
                class="my-1 my-sm-0"
                v-if="
                  !userAccount.transferred &&
                    !userAccount.hasSelectedInventory &&
                    !userAccount.isConfirmed
                "
                @click="transferPackageDialog = true"
                text
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                color="primary"
                small
                >Transfer Your Package</v-btn
              >
              <v-btn
                id="contactBtn"
                class="my-1 my-sm-0 btn223 btn223-moving-gradient btn223-moving-gradient--red"
                text
                small
                @click="messageDialog = true"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                v-if="userAccount.canSendMessages"
                >Contact Us</v-btn
              >
            </v-card-title>

            <v-card-text class="text--primary">
              <v-row dense>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Name</div>
                      <div class="grey--text text--darken-1">
                        {{ userAccount.firstName + " " + userAccount.lastName }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Email</div>
                      <div class="grey--text text--darken-1">
                        {{ userAccount.email }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-if="userAccount.secondaryEmail"
                    >
                      <div class="body-2">Secondary Email</div>
                      <div class="grey--text text--darken-1">
                        {{ userAccount.secondaryEmail }}
                        <v-btn
                          small
                          text
                          color="primary"
                          icon
                          @click="editInfoDialog = true"
                          :loading="$store.state.loading"
                          :disabled="$store.state.loading"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Package Code</div>
                      <div class="grey--text text--darken-1 text-uppercase">
                        {{ eventReportWinner.packageCode }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Phone</div>
                      <div class="grey--text text--darken-1 text-uppercase">
                        {{ formatPhone(userAccount.phone) }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Destination</div>
                      <div class="grey--text text--darken-1">
                        {{ truncate(eventReportWinner.destination, 95) }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Number of Nights</div>
                      <div class="grey--text text--darken-1">
                        {{ eventReportWinner.numberNights }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Room Type</div>
                      <div class="grey--text text--darken-1">
                        {{ eventReportWinner.unitType }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Event Name</div>
                      <div class="grey--text text--darken-1">
                        {{ eventReport.eventName }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Event Date</div>
                      <div class="grey--text text--darken-1">
                        {{ formatDate(eventReport.eventDate) }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Travel by Date</div>
                      <div class="red--text font-weight-medium">
                        {{ formatDate(eventReportWinner.travelByDate) }}
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <div class="body-2">Package Reg. Date</div>
                      <div class="grey--text text--darken-1">
                        {{ formatDate(userAccount.dateRegistered) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              class="mt-6 d-flex flex-column flex-md-row justify-space-between align-center"
            >
              <v-btn
                v-if="userAccount.canBrowseInventory"
                class="my-2 my-md-0"
                outlined
                small
                color="primary"
                to="/browse"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                >Browse Travel Accommodations</v-btn
              >
              <v-btn
                class="my-2 my-md-0"
                color="primary"
                text
                small
                @click="editInfoDialog = true"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                >Edit Your Contact Information</v-btn
              >
              <v-btn
                id="faqBtn"
                class="my-1 my-sm-0 btn223 btn223-moving-gradient btn223-moving-gradient--red"
                text
                small
                @click="toggleFAQBox"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
              >
                {{ faqVisible ? "Frequently Asked Questions" : "Frequently Asked Questions" }}
              </v-btn>
              <!-- <v-btn
                class="my-2 mb-4 my-md-0"
                color="primary"
                text
                small
                href="https://drive.google.com/file/d/1lV5P7k5DQmED9PXmNNEppykymoTI3Nbm/view?usp=sharing"
                target="_blank"
                >Covid-19 Travel Information
                <v-icon small right>mdi-open-in-new</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- faq -->
      <v-row>
        <v-expand-transition>
          <v-card outlined v-if="faqVisible" class="faq-container">
            <v-card-title>
              <h1>Frequently Asked Questions</h1>
            </v-card-title>
            <v-card-text>
              <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
                <div class="faq-question" @click="toggleFAQ(index)">
                  <h3>{{ faq.question }}</h3>
                  <span class="arrow" :class="{ open: faq.open }">▼</span>
                </div>
                <transition name="fade">
                  <div v-if="faq.open" class="faq-answer">
                    <p>{{ faq.answer }}</p>
                  </div>
                </transition>
              </div>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-row>

      <!-- timeline -->
      <v-row>
        <v-col cols="12" id="timeline">
          <v-card outlined :loading="$store.state.loading">
            <!-- pending confirmation alert -->
            <v-alert
              type="info"
              v-if="userAccount.pendingConfirmation"
              rounded
              text
              outlined
              icon="mdi-information-outline"
            >
              <h3 class="font-weight-medium">
                You've successfully selected an inventory option
              </h3>
              <p class="mt-2">
                Bookings through the SAV Traveler Portal are
                <strong
                  >not in real time and are subject to inventory
                  availability.</strong
                >
              </p>
              <p>
                Selecting inventory does not guarantee your accommodations.
                Therefore please
                <strong
                  >do not make any additional travel arrangements, including the
                  purchase of airfare or other transportation until you've
                  received an additional confirmation from us stating that it is
                  safe to do so.</strong
                >
              </p>

              <p>
                Once we've completed your booking, it is final. Our suppliers do
                not allow us to reschedule, change, or cancel your booking.
              </p>

              <p>
                Please keep an eye on your email inbox for a confirmation from
                us (usually within 3 business days).
              </p>

              <p>
                If you need to contact us, please click the
                <strong>I Need Help</strong> button above and send us a message.
              </p>
            </v-alert>

            <!-- booking confirmed alert -->
            <v-alert
              type="success"
              rounded
              :icon="false"
              text
              outlined
              v-if="userAccount.isConfirmed && confirmation"
            >
              <v-row>
                <v-col cols="12">
                  <h3 class="font-weight-medium">
                    We've booked your travel. It is now safe to purchase
                    transportation, airfare, etc.
                  </h3>
                  <p class="mt-4 font-weight-medium">Your Travel Details:</p>

                  <span class="d-block"
                    >Confirmation #: {{ confirmation.confirmationNumber }}</span
                  >
                  <span class="d-block"
                    >Traveler Name (must be present at time of check in):
                    {{
                      confirmation.firstName + " " + confirmation.lastName
                    }}</span
                  >
                  <span class="d-block"
                    >Destination: {{ confirmation.destination }}</span
                  >
                  <span class="d-block"
                    >Resort Name: {{ confirmation.resortName }}</span
                  >

                  <div class="my-2">
                    <span class="d-block">Resort Address:</span>
                    <span class="d-block" v-html="confirmation.resortAddress" />
                  </div>

                  <span class="d-block"
                    >Check In Date:
                    {{ formatDate(confirmation.checkInDate) }}</span
                  >
                  <span class="d-block"
                    >Check Out Date:
                    {{ formatDate(confirmation.checkOutDate) }}</span
                  >
                  <span class="d-block my-2"
                    >Number of Nights: {{ confirmation.numberOfNights }}</span
                  >
                  <span class="d-block" v-if="confirmation.bedrooms"
                    >Bedrooms: {{ confirmation.bedrooms }}</span
                  >
                  <span class="d-block"
                    >Kitchen: {{ confirmation.kitchen }}</span
                  >
                  <span class="d-block" v-if="confirmation.travelerNotes"
                    >Notes from us: {{ confirmation.travelerNotes }}</span
                  >
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn
                    outlined
                    color="error"
                    @click="onEmailUserTravelDetails"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    >Email Your Travel Details to Yourself</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>

            <!-- message sent alert -->
            <v-alert
              type="success"
              rounded
              text
              outlined
              icon="mdi-alert-outline"
              v-if="!userAccount.canSendMessages"
            >
              <div style="max-width: 768px" class="mb-0">
                <p>You've successfully sent your message.</p>
                <p>
                  We hope to reply to your message
                  <strong>within 7 business days.</strong>
                </p>
                <p>
                  <strong
                    >You'll receive an email when we've replied to your
                    message.</strong
                  >
                </p>
                <p>
                  Please be sure to check your inbox
                  <strong>(and spam)</strong> over the next 7 business days.
                </p>
                <p>Thank you</p>
              </div>
            </v-alert>

            <!-- timeline items -->
            <v-card-text>
              <div
                class="headline font-weight-medium text-center mb-4 text--primary"
              >
                Your Timeline
              </div>
              <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                <v-timeline-item
                  v-for="(item, index) in userAccount.timeline"
                  :key="index"
                  :icon="item.icon"
                  :color="item.color"
                  style="user-select: none"
                >
                  <v-card
                    :outlined="$vuetify.theme.dark ? true : false"
                    :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                    elevation="1"
                  >
                    <v-card-title class="headline">
                      {{ item.title }}
                    </v-card-title>
                    <v-card-text class="text--primary">
                      <p>
                        Date:
                        {{ formatDate(item.dateCreated) }}
                      </p>
                      <p v-if="item.message">Message: {{ item.message }}</p>
                    </v-card-text>
                    <v-card-actions
                      v-if="
                        item.senderActionRequired &&
                          item.message.includes('transfer')
                      "
                    >
                      <v-spacer />
                      <v-btn
                        v-if="
                          !userAccount.transferred &&
                            userAccount.canBrowseInventory
                        "
                        @click="transferPackageDialog = true"
                        text
                        :loading="$store.state.loading"
                        :disabled="$store.state.loading"
                        color="info"
                        >Transfer My Package</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <!-- timeline items -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- edit info dialog -->
      <v-dialog v-model="editInfoDialog" width="500" persistent>
        <v-card outlined :loading="$store.state.loading">
          <v-card-text class="text--primary">
            <h2 class="headline font-weight-medium mt-5">
              Edit Your Contact Information
            </h2>
            <p class="mt-4">
              The information below is available for you to edit.
            </p>
            <p>
              <strong>Your primary email addresses cannot be changed.</strong>
            </p>
            <p>
              If you'd like to receive communications to another email address,
              please add it to the seconary email field below.
            </p>
            <p>
              <i
                >Please note that all SAV communications will be copied to your
                secondary email address.</i
              >
            </p>
            <v-divider class="mt-8" />
            <v-form
              ref="editInfoForm"
              @submit.prevent="onEditInfo"
              class="mt-12 mb-4"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Phone *"
                    @keypress="limitPhoneValues($event)"
                    v-model.trim="userAccount.phone"
                    @keydown.enter="onEditInfo"
                    persistent-hint
                    counter
                    outlined
                    type="number"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    hint="Please enter numbers only. +1 is not necessary."
                    :rules="[textRules.required, phoneRules.tenNumbers]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model.trim="userAccount.secondaryEmail"
                    hint="Add a secondary email address to receive copies of all SAV communications sent to your primary email address"
                    label="Secondary Email (Optional)"
                    type="email"
                    @keydown.enter="onEditInfo"
                    persistent-hint
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    :rules="[emailRules.secondary]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              outlined
              @click="editInfoDialog = false"
              :loading="$store.state.loading"
              :disabled="$store.state.loading"
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="onEditInfo"
              :loading="$store.state.loading"
              :disabled="$store.state.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- terms dialog -->
      <v-dialog v-model="termsDialog" width="500" id="termsDialog">
        <v-card outlined :loading="$store.state.loading">
          <v-card-title class="headline font-weight-medium my-3">
            Your Package Terms
          </v-card-title>
          <v-card-text class="text--primary pl-3" v-if="userAccount">
            <div v-html="userAccount.termsAndConditions.content" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="termsDialog = false"
              :loading="$store.state.loading"
              :disabled="$store.state.loading"
            >
              Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- message dialog -->
      <v-dialog v-model="messageDialog" width="500">
        <v-card outlined :loading="$store.state.loading">
          <v-card-title class="headline font-weight-medium my-3">
            Contact Us
          </v-card-title>
          <v-card-text class="text--primary body-1">
            <p>
              Please enter your message to our Booking Team below! We'll get
              back to you as soon as possible! Our team is always assisting
              travelers just like you and you can expect a response in roughly
              24 to 72 hours!
            </p>
            <p>
              Please know that we do not utilize AI and you will receive a
              response from a live member of our team to all of your inquiries!
            </p>
            <p>We look forward to hearing from you!</p>
            <p>
              <strong
                >You'll receive an email notification when we've
                replied.</strong
              >
            </p>
            <v-form ref="messageForm" @submit.prevent="onSendMessage">
              <v-textarea
                outlined
                v-model.trim="message.message"
                :loading="$store.state.loading"
                :disabled="$store.state.loading"
                label="Message *"
                :rules="[textRules.required]"
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              outlined
              @click="messageDialog = false"
              :loading="$store.state.loading"
              :disabled="$store.state.loading"
              >Cancel</v-btn
            >

            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="onSendMessage"
              :disabled="$store.state.loading"
              :loading="$store.state.loading"
            >
              Send Message</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- isConfirmed dialog -->
      <v-dialog width="500" persistent v-model="isConfirmedDialog">
        <v-card outlined :loading="$store.state.loading">
          <v-card-title class="headline font-weight-medium my-3">
            Your Booking Is Confirmed
          </v-card-title>
          <v-card-text class="text--primary body-1">
            <p>
              Your reservation will appear in your resort's booking system
              <strong
                >no earlier than 14 days prior to your arrival date</strong
              >
              of {{ arrivalDate }}.
            </p>
            <p>
              Confirming your reservation is not necessary, but if you'd like to
              contact your resort, please contact your resort
              <strong>after {{ fourteenDaysPriorToArrivalDate }}.</strong>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="closeMe"
              color="primary"
              depressed
              @click="closeIsConfirmedDialog"
              :disabled="$store.state.loading"
              :loading="$store.state.loading"
            >
              Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- transferPackage dialog -->
      <v-dialog v-model="transferPackageDialog" width="500">
        <v-card outlined :loading="$store.state.loading">
          <v-card-title class="headline font-weight-medium my-3">
            Transfer Your Package
          </v-card-title>
          <v-card-text class="text--primary body-1">
            <p>
              Use the form below to transfer your package. Enter the last name
              and email address of the traveler receiving your package.
            </p>
            <p>
              <strong
                >The package travel by date will not be changed. Packages may
                only be transferred once.</strong
              >
            </p>
            <p>
              <strong
                >Please note that you will no longer be able to access this
                dashboard or use this package once you've submitted this form.
                This action cannot be undone.</strong
              >
            </p>
            <v-form ref="transferPackageForm" class="my-10">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Last Name"
                    :rules="[textRules.required]"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    v-model.trim="transferToLastName"
                    outlined
                  ></v-text-field
                ></v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Email"
                    :rules="[textRules.required, emailRules.valid]"
                    :loading="$store.state.loading"
                    :disabled="$store.state.loading"
                    v-model.trim="transferToEmailAddress"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              outlined
              @click="transferPackageDialog = false"
              :loading="$store.state.loading"
              :disabled="$store.state.loading"
              >Cancel</v-btn
            >

            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="transferPackage"
              :disabled="$store.state.loading"
              :loading="$store.state.loading"
            >
              Transfer Your Package</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--  -->
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Handlebars from "handlebars";
import axios from "axios";
import firebase from "@/plugins/firebase";
import onSignOut from "@/mixins/onSignOut";
import formatPhone from "@/mixins/formatPhone";
import formatDate from "@/mixins/formatDate";
import formRules from "@/mixins/formRules";
import limitPhoneValues from "@/mixins/limitPhoneValues";
import truncate from "@/mixins/truncate";

Handlebars.registerHelper("date", (dateISO) => {
  return moment(dateISO).format("ddd, MM/DD/YYYY");
});

const userAccounts = firebase.firestore().collection("userAccounts");
const eventReports = firebase.firestore().collection("eventReports");
const bookingsConfirmations = firebase
  .firestore()
  .collection("bookingsConfirmations");

export default {
  mixins: [
    onSignOut,
    formatPhone,
    formatDate,
    formRules,
    limitPhoneValues,
    truncate,
  ],
  firestore: {
    userAccounts: firebase.firestore().collection("userAccounts"),
    eventReports: firebase.firestore().collection("eventReports"),
    bookingsConfirmations: firebase
      .firestore()
      .collection("bookingsConfirmations"),
  },
  async mounted() {
    this.$store.state.loading = true;
    // bind userAccount
    await this.$bind("userAccount", userAccounts.doc(this.$store.state.userID));

    // bind eventReport
    await this.$bind(
      "eventReport",
      eventReports.doc(this.userAccount.eventReportID)
    );

    // set eventReportWinner
    this.eventReportWinner = this.eventReport.winners.find(
      (i) => i.packageID == this.userAccount.packageID
    );

    // bind bookingConfirmation
    if (this.userAccount.isConfirmed) {
      let confirmations = await this.$bind(
        "confirmation",
        bookingsConfirmations
          .where("packageID", "==", this.userAccount.packageID)
          .where("archived", "==", false)
          .where("isConfirmed", "==", true)
      );
      if (confirmations.length) {
        this.confirmation = confirmations[0];
        this.isConfirmedDialog = true;
      }
    }
    this.$store.state.loading = false;
  },
  data: () => ({
    userAccount: "",
    eventReport: "",
    eventReportWinner: "",
    confirmation: "",
    transferToEmailAddress: "",
    transferToLastName: "",
    transferredDate: new Date().toISOString().substring(0, 10),
    editInfoDialog: false,
    termsDialog: false,
    messageDialog: false,
    termsDialog: false,
    messageDialog: false,
    isConfirmedDialog: false,
    transferPackageDialog: false,
    faqVisible: false,
    message: {
      adminActionRequired: true,
      archived: false,
      dateCreated: new Date().toISOString().substring(0, 10),
      from: "",
      message: "",
      readByAdmin: false,
      repliedToByAdmin: false,
      senderActionRequired: false,
      title: "You sent us a message",
      to: "admin",
      icon: "mdi-message-text-outline",
      color: "primary",
    },
    faqs: [
        { 
          question: "Can I extend my package?",
          answer: "As all Charity Getaways packages are provided with 3-Years to travel, they are not eligible for extension.",
          open: false,
        },
        {
          question: "What is my Travel-by Date?",
          answer: "Your Travel-by Date is the latest possible date that you may select for travel before your package expires.",
          open: false,
        },
        {
          question: "Can a booking agent contact me directly via phone or email to complete my booking request?",
          answer: "No, we are unable to conduct bookings via phone or email. All selections must be made via our booking platform. All communication regarding your package must go through the internal messaging system, accessed by clicking 'Contact Us' on your Traveler Dashboard, for privacy, security, and record-keeping purposes. The messaging system is NOT automated and you are always communicating with a live person.",
          open: false,
        },
        {
          question: "What if I don't see the dates I want?",
          answer: "Packages are valid for travel for 3-years from their event date, however it is not possible to load inventory for 3-full years in advance as we must await the release of additional inventory from our suppliers. Inventory is generally displayed 90-180+ days from today each day and dates for the next year can be released as early as June of the current year to as late as December of the current year. We have no control over when our suppliers release additional dates. If you do not see the dates that you are looking for, we recommend that you check back often as inventory is updated regularly and as soon as additional dates become available to us from our suppliers, they will appear within the inventory system for selection.",
          open: false,
        },
        {
          question: "How do I book my vacation?",
          answer: "To begin the booking process, click the blue button titled 'Browse Travel Accommodations' on your Traveler Dashboard. You will be taken to the inventory page where you may browse Alternative Destinations and Original Package Destinations. To review an inventory option, click 'Book/More Info'. You will be taken to a page that provides you with information about the resort and its available amenities. If you would like to book the date, destination, and resort that you are reviewing, click the button titled 'Select This Booking' and complete the pop-up as required. You will receive a message confirming that you've made an inventory selection. Please do not make any travel arrangements until you've received a confirmation from us.",
          open: false,
        },
        {
          question: "What are Alternative Destinations?",
          answer: "Alternative Destinations are limited destination and resort options in the US, Canada, and Bali that provide availability 30-days from today each day. These options are subject to change at any time without warning as they are based upon availability from our suppliers of Alternative Destinations. There is no additional fee to make a selection from Alternative Destinations as opposed to Original Package Destinations.",
          open: false,
        },
        {
          question: "What is a High Occupancy Surcharge?",
          answer: "A High Occupancy Surcharge is an upgrade to a larger unit size that is not covered by your package. This is a flat-rate fee, not per night. You can avoid this fee by selecting a unit size that is covered by your package.",
          open: false,
        },
        {
          question: "What is a Peak Season Surcharge?",
          answer: "A Peak Season Surcharge is a fee for travel during a peak traveling period as outlined by our suppliers. This is a flat-rate fee, not per night. You can avoid this fee by selecting travel dates that fall outside of peak traveling periods as outlined by our suppliers.",
          open: false,
        },
        {
          question: "What is a Third-Year Surcharge?",
          answer: "A Third-Year Surcharge is a fee for travel in the third and final year of your package's lifetime and is imposed due to the uncertainty of accommodation costs over the three-year travel period of your package. This is a flat-rate fee. You can avoid this fee by traveling before the third year of your package's lifetime.",
          open: false,
        },
        {
          question: "Can I book dates that are sooner than 90-days from today?",
          answer: "Our suppliers require selections of original package destinations to be made 90-days prior to your desired arrival date. As such, it is not possible to book a stay that is less than 90-days in advance of today.",
          open: false,
        },
        {
          question: "Can I split my stay?",
          answer: "Due to agreements with our suppliers, it is not possible to split your stay among different destinations or resorts. All packages are for consecutive night stays and may not be broken up.",
          open: false,
        },
        {
          question: "Can I cancel my confirmed reservation?",
          answer: "All reservations are final. Our suppliers do not permit us to make changes of any kind, for any reason, to confirmed bookings. This includes, but is not limited to, cancellations, date changes, name changes, unit changes, resort changes, destination changes, etc.",
          open: false,
        },
        {
          question: "What do I do if the resort does not have me in their system when I call ahead to confirm?",
          answer: "As we do not often book directly with resorts and utilize third-party suppliers, your reservation may not appear within the resort's immediate system until roughly 14-days prior to your arrival date. Should you contact the resort less than 14-days prior to your arrival date and they cannot locate your reservation in their system, please reach out to us immediately by clicking 'Contact Us' on your Traveler Dashboard and we will be happy to look into it for you.",
          open: false,
        },
        {
          question: "Can I transfer my package?",
          answer: "If your package has not yet been used for travel, you may transfer it to a friend or family member as a gift. There is no fee to transfer your package to another person. You can transfer your package to another person by clicking the button titled 'Transfer Your Package' on the top of your Traveler Dashboard. In the pop-up window, provide the name of the new package holder and their email address. Please be sure to double-check the entered email address as this action cannot be undone prior to submitting that form. If the new package holder has issues locating the transferred package, please provide them with the following email address for assistance: info@charitygetaways.com.",
          open: false,
        },
        {
          question: "How do I sort the inventory?",
          answer: "Inventory can be sorted by only one column at a time. You may sort the inventory by Destination, Resort Name, Check-In Date, Check-Out Date, # of Nights, Bedrooms, Max Occupancy, Kitchen, or by Total Surcharge. To sort by one of these options, simply click the title of the column such as 'Resort Name' to list all options alphabetically then scroll until you see your desired option.",
          open: false,
        },
        {
          question: "I have multiple packages and can't seem to register the others I hold. What do I do?",
          answer: "Each package that you are holding must be registered with a unique primary email address. You may place your preferred email address as the secondary email on your other packages and it will still receive all the same information that the primary email address will receive regarding that package. It is not possible to view multiple packages on the same dashboard. Each package will have its own dashboard.",
          open: false,
        },
      ],
  }),
  computed: {
    arrivalDate() {
      if (!this.confirmation) return;
      return moment(this.confirmation.checkInDate).format("ddd, MM/DD/YYYY");
    },
    fourteenDaysPriorToArrivalDate() {
      if (!this.confirmation) return;
      let arrivalDate = moment(this.confirmation.checkInDate);
      let fourteenDaysPriorToArrivalDate = moment(arrivalDate).subtract(
        14,
        "days"
      );
      return moment(fourteenDaysPriorToArrivalDate).format("ddd, MM/DD/YYYY");
    },
  },
  methods: {
    closeIsConfirmedDialog() {
      this.isConfirmedDialog = false;
      let elements = [];
      let nodeList = document.querySelectorAll(".closeMe");
      elements = Array.from(nodeList);
      if (!elements.length) return;
      elements.forEach((i) => {
        i.click();
      });
    },
    async onEmailUserTravelDetails() {
      this.$store.state.loading = true;

      // data for html template and nodemailer
      let emailData = {
        subject: "Your travel details from the SAV Traveler Portal",
        to: [
          process.env.NODE_ENV.trim() == "development"
            ? "bobdempsey83@gmail.com"
            : this.userAccount.email,
        ],
        cc: [
          process.env.NODE_ENV.trim() != "development" &&
          this.userAccount.secondaryEmail
            ? this.userAccount.secondaryEmail
            : "",
        ],
        html: "",
      };

      // email template
      let template = Handlebars.compile(`
    <div style="font-family: 'Roboto', sans-serif; max-width: 768px;">
    <p>
        <small>
            <i> This is an automatically generated email from an unattended email inbox. <strong>Please do not reply directly to this email.</strong> </i>
        </small>
    </p>

    <h3>Your travel detals from the SAV Traveler Portal</h3>

    <p>Confirmation #: {{confirmationNumber}}</p>

    <p>Traveler Name (must be present at time of check in): {{firstName}} {{lastName}}</p>

    <p>Destination: {{destination}}</p>

    <p>Resort Name: {{resortName}}</p>

    <p>
    <span style="display:block">Resort Address:</span>
    {{{resortAddress}}}
    </p>

    <p>Check In Date: {{date checkInDate}}</p>

    <p>Check Out Date: {{date checkOutDate}}</p>

    <p>Number of Nights: {{numberOfNights}}</p>

    {{#if bedrooms}}
    <p>Bedrooms: {{bedrooms}}</p>
    {{/if}}

    <p>Kitchen: {{kitchen}}</p>

    {{#if travelNotes}}
    <p>Notes from us: {{travelerNotes}}</p>
    {{/if}}

    <p>If you'd like to access your dashboard again, please use the link below:</p>

    <p>
    <a href="https://savtravelerportal.com/sign-in">https://savtravelerportal.com/sign-in</a>
    </p>

    <p>Thank you</p>

    <p>
        <span style="display: block;">The Booking Team</span>
        <span style="display: block;">Silent Auction Vacations</span>
    </p>

    <p>
        <small>
            <i> This is an automatically generated email from an unattended email inbox. <strong>Please do not reply directly to this email.</strong> </i>
        </small>
    </p>
    </div>
    `);

      // construct email
      emailData.html = template(this.confirmation);

      // send email
      try {
        await axios.post(
          "https://ejtw4r3nl5eoplt2qiussbc7pm0lspoz.lambda-url.us-east-1.on.aws/",
          {
            emailData,
          }
        );
        // admin notification
        await firebase
          .firestore()
          .collection("adminNotifications")
          .add({
            archived: false,
            dateAdded: new Date().toISOString().substring(0, 10),
            dateArchived: "",
            text: `[Traveler Details Emailed - Traveler Portal] ${this.userAccount.email} has sent themselves their travel details.`,
          });
        this.$toast.open({
          message:
            "Travel details sent. Please check your spam folder if you have not received them.",
          type: "success",
        });
        this.$store.state.loading = false;
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: `Error: this.onEmailUserTravelDetails() - ${error}`,
          type: "error",
        });
        this.$store.state.loading = false;
      }
    },
    async onSendMessage() {
      if (!this.$refs.messageForm.validate())
        return this.$toast.open({
          message: "Please check form for errors",
          type: "error",
        });

      this.$store.state.loading = true;
      this.message.from = this.userAccount.email;

      firebase
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id)
        .update({
          timeline: firebase.firestore.FieldValue.arrayUnion(this.message),
          nodeMailer: { created: false, newMessage: false },
        })
        .then(() => {
          firebase
            .firestore()
            .collection("adminNotifications")
            .add({
              archived: false,
              dateAdded: new Date().toISOString().substring(0, 10),
              dateArchived: "",
              text: `[New Message Received - from Traveler Portal] Name: ${this.userAccount.firstName} ${this.userAccount.lastName}, Email: ${this.userAccount.email}, Message: ${this.message.message}`,
            })
            .then(() => {
              this.$toast.open({
                message: "Message Sent Successfully",
                type: "success",
              });
              this.messageDialog = false;
              this.message.message = "";
              this.$store.state.loading = false;
            });
        })
        .catch((error) => {
          this.$store.state.loading = false;
          console.error(error);
          this.$toast.open({
            message: "Error sending message",
            type: "error",
          });
        });
    },
    async onEditInfo() {
      if (!this.$refs.editInfoForm.validate())
        return this.$toast.open({
          message: "Please check form for errors",
          type: "error",
        });

      this.$store.state.loading = true;
      firebase
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id)
        .update({
          phone: this.userAccount.phone,
          secondaryEmail: this.userAccount.secondaryEmail,
          dateUpdated: new Date().toISOString().substring(0, 10),
        })
        .then(() => {
          this.$toast.open({
            message: "Information Edited Successfully",
            type: "success",
          });
          this.$store.state.loading = false;
          this.editInfoDialog = false;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          console.error(error);
          this.$toast.open({
            message: "Error editing information",
            type: "error",
          });
        });
    },
    async transferPackage() {
      // revoke current user booking & inventory permissions
      // allow re-registration of package
      // send email to current and new user => bcc booking
      // add admin notification
      // sign out

      if (!this.$refs.transferPackageForm.validate())
        return this.$toast.open({
          message: "Please check form for errors",
          type: "error",
        });

      this.$store.state.loading = true;

      // create timeline message
      this.message.adminActionRequired = false;
      this.message.from = "admin";
      this.message.readByAdmin = true;
      this.message.repliedToByAdmin = true;
      this.message.message = `You transferred your package to ${this.transferToEmailAddress}`;
      this.message.to = this.userAccount.email;
      this.message.icon = "mdi-account-arrow-right-outline";
      this.message.color = "success";
      this.message.title = "You transferred your package";

      // revoke current user booking & inventory permissions
      await firebase
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id)
        .update({
          canBook: false,
          canBrowseInventory: false,
          canAccess: false,
          transferred: true,
          transferredToLastName: this.transferToLastName,
          transferredToEmailAddress: this.transferToEmailAddress,
          transferredDate: this.transferredDate,
          timeline: firebase.firestore.FieldValue.arrayUnion(this.message),
        });

      // allow re-registration of package within event report

      // set current package isRegistered to false
      this.eventReportWinner.isRegistered = false;

      // update winners array in eventReport doc
      await firebase
        .firestore()
        .collection("eventReports")
        .doc(this.eventReport.id)
        .update({
          winners: this.eventReport.winners,
        });

      // send emails to current and new user
      let currentUserEmailData = {
        subject: `You've successfully transferred your SAV travel package to ${this.transferToEmailAddress}`,
        to: [
          process.env.NODE_ENV.trim() == "development"
            ? "bobdempsey83@gmail.com"
            : this.userAccount.email,
        ],
        cc: [
          process.env.NODE_ENV.trim() != "development" &&
          this.userAccount.secondaryEmail
            ? this.userAccount.secondaryEmail
            : "",
        ],
        bcc: [
          process.env.NODE_ENV.trim() != "development"
            ? "booking@silentauctionvacations.com"
            : "",
        ],
        html: "",
      };

      let currentUserTemplate = Handlebars.compile(`
      <div style="font-family: 'Roboto', sans-serif; max-width: 768px;">
    <p>
        <small>
            <i> This is an automatically generated email from an unattended email inbox. <strong>Please do not reply to this email.</strong> </i>
        </small>
    </p>

    <h3>You've successfully transferred your SAV travel package</h3>

    <p>Package transfer details:</p>

    <p>Last Name: {{ lastName }}</p>

    <p>Recipient Email: {{ email }}</p>

    <p>Date Transferred: {{ date date }}</p>

    <p><strong>{{ email }} has also been sent an email to notify them of the transfer. Please ask them to keep an eye on their email inbox and spam folders if has not arrived soon.</strong></p>

    <p>Please keep this email for your records.</p>

    <p>Thank you</p>

    <p>
        <span style="display: block;">The Booking Team</span>
        <span style="display: block;">Silent Auction Vacations</span>
    </p>

    <p>
        <small>
            <i> This is an automatically generated email from an unattended email inbox. <strong>Please do not reply to this email.</strong> </i>
        </small>
    </p>
</div>
      `);

      // construct currentUser email
      currentUserEmailData.html = currentUserTemplate({
        email: this.transferToEmailAddress,
        lastName: this.transferToLastName,
        date: this.transferredDate,
      });

      // send currentUser email
      try {
        await axios.post(
          "https://ejtw4r3nl5eoplt2qiussbc7pm0lspoz.lambda-url.us-east-1.on.aws/",
          {
            emailData: currentUserEmailData,
          }
        );
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: `Error transferring your package. Please email us at booking@silentauctionvacations.com`,
          type: "error",
          duration: 0,
        });
        this.$store.state.loading = false;
      }

      let newUserEmailData = {
        subject: `${this.userAccount.email} has transferred their SAV travel package to you`,
        to: [
          process.env.NODE_ENV.trim() == "development"
            ? "bobdempsey83@gmail.com"
            : this.transferToEmailAddress,
        ],
        bcc: [
          process.env.NODE_ENV.trim() != "development"
            ? "booking@silentauctionvacations.com"
            : "",
        ],
        html: "",
      };

      // new user email
      // this is the same as a new congrats email
      let newUserTemplate = Handlebars.compile(`
      <div style="font-family: 'Roboto', sans-serif; max-width: 768px;">
    <p>
        <small>
            <i> This is an automatically generated email from an unattended email inbox. <strong>Please do not reply to this email.</strong> </i>
        </small>
    </p>

    <h3>{{ userEmail }} has transferred their SAV travel package to you</h3>

    <p>Please use the link below to <strong>register your package and get additional information.</strong></p>
    <p>If you'd like to access your dashboard, or contact us, please use the link below. <i>Do not reply to this email.</i></p>
    <p>If the link is not clickable, please copy and paste the address into your browser.</p>
    <p style="color: red;"><strong>THIS LINK IS A PASSWORD. DO NOT SHARE IT.</strong></p>
    <p>Anyone who has a copy of this link will be able to use your travel package.</p>
    <p>Click or copy/paste the blue link below to proceed:</p>

    <p><a href="https://savtravelerportal.com/register?puid={{packageID}}" target="_blank">https://savtravelerportal.com/register?puid={{packageID}}</a></p>

    <p>Thank you</p>

    <p>
        <span style="display: block;">The Booking Team</span>
        <span style="display: block;">Silent Auction Vacations</span>
    </p>

    <p>
        <small>
            <i> This is an automatically generated email from an unattended email inbox. <strong>Please do not reply to this email.</strong> </i>
        </small>
    </p>
</div>`);

      // construct newUser email
      newUserEmailData.html = newUserTemplate({
        userEmail: this.userAccount.email,
        packageID: this.userAccount.packageID,
      });

      // send newUser email
      try {
        await axios.post(
          "https://ejtw4r3nl5eoplt2qiussbc7pm0lspoz.lambda-url.us-east-1.on.aws/",
          {
            emailData: newUserEmailData,
          }
        );
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: `Error transferring your package. Please email us at booking@silentauctionvacations.com`,
          type: "error",
          duration: 0,
        });
        this.$store.state.loading = false;
      }

      // admin notification
      await firebase
        .firestore()
        .collection("adminNotifications")
        .add({
          archived: false,
          dateAdded: new Date().toISOString().substring(0, 10),
          dateArchived: "",
          text: `[Package Transferred - Traveler Portal] ${this.userAccount.email} has transferred their package to ${this.transferToEmailAddress}. Both travelers have been notified via email.`,
        });

      // sign out
      this.onSignOut();
      this.$toast.open({
        message: `Package transfer complete`,
        type: "info",
        duration: 0,
      });

      this.$store.state.loading = false;
    },
    toggleFAQBox() {
      this.faqVisible = !this.faqVisible;
    },
    toggleFAQ(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
  },
  watch: {
    // confirmation(val) {
    //   if (val) {
    //     if (val.isConfirmed) {
    //       if (val.isConfirmed && !this.isConfirmedDialog) {
    //         this.isConfirmedDialog = true;
    //       }
    //     }
    //   }
    // },
    "$store.state.userID": {
      immediate: true,
      async handler(userID) {
        if (!userID || userID == null || userID == undefined)
          return this.$router.push("/");
      },
    },
  },
};
</script>
<style>
.faq-container {
  max-width: 100%;
  margin: 12px;
  padding: 20px;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}

.faq-answer {
  padding: 10px 0;
}

.arrow {
  transition: transform 0.2s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.btn223 {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.btn223:hover .btn223-slide-show-text1 {
  margin-left: 65px;
}
#contactBtn {
  width: 150px !important;
}
#faqBtn {
  width: 300px !important;
  margin-bottom: 10px !important;
}
.btn223-moving-gradient {
  font-size: 16px;
  font-weight: 600;
  color: rgb(255, 255, 255) !important;
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
}
.btn223-moving-gradient:hover {
  transition: 0.5s ease-in-out;
  background-position: 100% 0px;
}
.btn223-moving-gradient--red {
  background-image: linear-gradient(
    90deg,
    rgb(239, 37, 60),
    rgb(239, 148, 37),
    rgb(239, 104, 37),
    rgb(239, 35, 60)
  );
  box-shadow: rgb(239, 148, 37) 0px 4px 15px 0px;
}
</style>